<template>
  <div>
    <b-card title="Create Person">
      <div class="d-flex justify-content-center flex-wrap">
        <b-form-group>
          <b-input-group>
            <b-button variant="outline-primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :to="{ name: 'persons', params: { idEvent: this.idEvent } }">
              <!-- , params: { idEvent: data.idEvent} -->
              <feather-icon icon="ArrowLeftCircleIcon" class="mr-0 mr-sm-50"></feather-icon>
              Go Back
            </b-button>
          </b-input-group>
        </b-form-group>
      </div>
      <validation-observer ref="simpleRules">
        <b-form>
          <template #title>
            <feather-icon icon="FileTextIcon" />
            <span>Create Person</span>
          </template>

          <b-row>
            <b-col cols="1"></b-col>
            <b-col>
              <b-form-group>
                <h4>Id Event</h4>
                <b-form-input id="input-default" size="lg" v-model="event.pkid_mxs_event" :plaintext="togglePlaintext"
                  plaintext />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <h4>Season</h4>
                <b-form-input id="input-default" size="lg" v-model="event.Temporada" :plaintext="togglePlaintext"
                  plaintext />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <h4>Aforo Name</h4>
                <b-form-input id="input-default" size="lg" v-model="event.Aforo_name" :plaintext="togglePlaintext"
                  plaintext />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <h4>Event Name</h4>
                <b-form-input id="input-default" size="lg" v-model="event.event_name" :plaintext="togglePlaintext"
                  plaintext />
              </b-form-group>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>

          <b-row>
            <b-col cols="1"></b-col>
            <b-col>
              <b-form-group>
                <validation-provider #default="{ errors }" name="Name" rules="required|min:3">
                  <h4>Name</h4>
                  <b-form-input id="input-default" size="lg" v-model="body.Nom_titular" :plaintext="togglePlaintext"
                    required :state="errors.length > 0 ? false : null" autofocus />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col>
              <b-form-group>
                <validation-provider #default="{ errors }" name="Surname1" rules="required">
                  <h4>Surname1</h4>
                  <b-form-input id="input-default" size="lg" v-model="body.Cognom1_titular" :plaintext="togglePlaintext"
                    required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>

          <b-row>
            <b-col cols="1"></b-col>
            <b-col>
              <b-form-group>
                <h4>Surname2</h4>
                <b-form-input id="input-default" size="lg" v-model="body.Cognom2_titular" :plaintext="togglePlaintext" />
              </b-form-group>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col>
              <b-form-group>
                <h4>DOI</h4>
                <b-form-select size="lg" v-model="body.DOI_Type" :options="dniOptions" :disabled="dateDisabled" />
                <validation-provider #default="{ errors }" name="DOI" rules="required|dni">
                  <b-form-input v-if="body.DOI_Type === 'DNI'" id="input-default" size="lg" maxlength="9"
                    v-model="body.DNI" :plaintext="togglePlaintext" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <validation-provider v-if="body.DOI_Type === 'Other'" #default="{ errors }" name="DOI" rules="required">
                  <b-form-input size="lg" :plaintext="togglePlaintext" v-model="body.DNI" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>

          <b-row>
            <b-col cols="1"></b-col>
            <b-col>
              <b-form-group>
                <h4>Car Plate</h4>
                <b-form-input id="input-default" size="lg" v-model="body.Matricula" :plaintext="togglePlaintext" />
              </b-form-group>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col>
              <b-form-group>
                <h4>SCA Acces</h4>
                <b-form-select size="lg" v-model="body.AccesSCA" :options="dooraccesoptions" :disabled="dateDisabled" />
              </b-form-group>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>

          <b-row>
            <b-col cols="1"></b-col>
            <b-col>
              <b-form-group>
                <h4>Doc Status</h4>
                <b-form-select size="lg" v-model="docStatusSelected" :options="docStatusOptions"
                  @change="configureDocStatus()" :disabled="dateDisabled" />
              </b-form-group>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col>
              <validation-provider #default="{ errors }" name="DOI" rules="required">
                <b-form-group :state="errors.length > 0 ? false : null">
                  <h4>Company Name</h4>
                  <b-form-select size="lg" v-model="companySeleceted"
                    :options="companies" @change="configureComany()" :disabled="dateDisabled" />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>

          <b-row>
            <b-col cols="1"></b-col>
            <b-col>
              <b-form-group>
                <h4>Barcode (Wristband)</h4>
                <b-form-input id="input-default" size="lg" v-model="body.Cod_Barras" :plaintext="togglePlaintext" />
              </b-form-group>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col>
              <b-form-group>
                <h4>Email</h4>
                <b-form-input id="input-default" size="lg" v-model="body.email" :plaintext="togglePlaintext" />
              </b-form-group>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>

          <b-row>
            <b-col cols="1"></b-col>
            <b-col>
              <b-form-group>
                <h4>Data Naixement</h4>
                <flat-pickr v-model="body.Data_naixement" class="form-control" :disabled="dateDisabled" />
              </b-form-group>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col>

            </b-col>
            <b-col cols="1"></b-col>
          </b-row>

          <b-row class="justify-content-center">
            <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" :hidden="hiddenComponent"
              @click.prevent="validationForm">
              <feather-icon icon="SaveIcon" class="mr-0 mr-sm-50"></feather-icon>
              Create Person
            </b-button>
            <b-button variant="outline-primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :to="{ name: 'persons', params: { idEvent: this.idEvent } }">
              <!-- , params: { idEvent: data.idEvent} -->
              <feather-icon icon="ArrowLeftCircleIcon" class="mr-0 mr-sm-50"></feather-icon>
              Go Back
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { BTable, BCard, BCardText, BTabs, BTab, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend, BRow, BCol, BFormSelect, BCardBody, BPagination, BBadge, BFormInvalidFeedback, BFormValidFeedback } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import router from "@/router";
import { ref } from "@vue/composition-api";
import axios from "@/libs/axios";
import { extend } from 'vee-validate';
import { regex } from 'vee-validate/dist/rules';
import { decryptString } from '@/libs/crypto'

extend('dni', {
  ...regex,
  params: ['lazy'],
  validate: value => {
    if (/^(\d{8})([A-Z])$/.test(value)) {
      if ('TRWAGMYFPDXBNJZSQVHLCKE'.charAt(parseInt(value, 10) % 23) === value.charAt(8)) {
        return true;
      }
      return 'The DOI letter is incorrect';
    }
    return 'The DOI is not valid';
  }
});

export default {
  components: {
    BTable, BCard, BCardText, BTabs, BTab, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend, BRow, BCol, BFormSelect, BCardBody, BPagination, BBadge, BFormInvalidFeedback, BFormValidFeedback, ValidationProvider, ValidationObserver,

    flatPickr,
  },
  computed: {
    docStatusOptions() {
      return this.docStatus;
    },
    configureDocStatus() {
      const docStatus = this.docStatusSelected.split(" - ");
      this.body.fkid_accreditation_status = docStatus[0]
      this.body.DocStatus = docStatus[1];
    },
    dooraccesoptions() {
      this.dooraccesSelected =
        this.dooracces = this.dooracces.map((t) => ({ text: t, value: t }))
      return this.dooracces;
    },
  },
  methods: {
    configureComany() {
      const company = this.companySeleceted.split(" - ");
      const idCompany = company[1].replace(/[()]/g, "")
      this.body.fkid_accreditation_company = idCompany;
      this.body.Empresa_nom = company[0];
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.message();
        }
      })
    },
    async message() {
      const error = await this.onSubmit();
      if (error[0]) {
        const variant = "danger";
        this.$bvToast.toast("" + error[1], {
          title: "Failed",
          variant,
          solid: true,
        });
      } else if (!error[0]) {
        this.togglePlaintext = true;
        this.dateDisabled = true;
        this.hiddenComponent = true;
        const variant = "success";
        this.$bvToast.toast("Person created correctly", {
          title: `Update`,
          variant,
          solid: true,
        });
      }
    },
  },
  data() {
    return {
      required,
      dateDisabled: false,
      hiddenComponent: false,
      togglePlaintext: false,
      docStatus: [],
      companies: [],
      dooracces: [],
      docStatusSelected: "",
      event: [],
      dniOptions: [
        { text: 'DNI', value: 'DNI' },
        { text: 'Other', value: 'Other' },
      ],
      idEvent: router.currentRoute.params.idEvent,
      companySeleceted: ","
    };
  },
  async created() {

    await axios
      .get("/docStatus")
      .then((results) => {
        results.data.forEach(element => {
          this.docStatus.push(element.pkid_accreditation_status + " - " + element.Description)
        });
        this.docStatusSelected =
          this.docStatus = this.docStatus.map((t) => ({ text: t, value: t }));
        this.docStatusSelected = this.docStatus[0].text
        const docStatus = this.docStatusSelected.split(" - ");
        this.body.fkid_accreditation_status = docStatus[0]
        this.body.DocStatus = docStatus[1];
      })
      .catch((error) => {
        const variant = "danger";
        this.$bvToast.toast("Failed to connect", {
          title: `` + error,
          variant,
          solid: true,
        });
      })

    await axios
      .get("/company")
      .then((results) => {
        results.data.forEach(element => {
          this.companies.push(element.Name + " - (" + element.pkid_accreditation_company + ")")
        });
      })
      .catch((error) => {
        const variant = "danger";
        this.$bvToast.toast("Failed to connect", {
          title: `` + error,
          variant,
          solid: true,
        });
      });

    await axios
      .get("/event?pkid_mxs_event=" + router.currentRoute.params.idEvent)
      .then((results) => {
        this.event = results.data[0];
        this.body.fkid_mxs_event = this.event.pkid_mxs_event;
        this.body.Temporada = this.event.Temporada;
        this.body.ID_Aforo = this.event.ID_Aforo;
      })
      .catch((error) => {
        const variant = "danger";
        this.$bvToast.toast("Failed to connect", {
          title: `` + error,
          variant,
          solid: true,
        });
      })

    await axios
      .get("/dooracces?ID_Aforo=" + this.event.ID_Aforo)
      .then((results) => {
        results.data.forEach(element => {
          this.dooracces.push(element.AccesSCA)
        });
      })
      .catch((error) => {
        const variant = "danger";
        this.$bvToast.toast("Failed to connect", {
          title: `` + error,
          variant,
          solid: true,
        });
      })

  },
  mounted(){
    if (localStorage.getItem("accessToken") === null) {
      this.$router.go(0)
    }
  },
  setup() {
    const blankPersonData = {
      fkid_persona: 999,
      fkid_mxs_event: "",
      Temporada: "",
      ID_Aforo: "",
      Nom_titular: "",
      Cognom1_titular: "",
      Cognom2_titular: "",
      DNI: "",
      Matricula: "",
      fkid_accreditation_company: "",
      fkid_accreditation_status: "",
      Empresa_nom: "",
      Cod_Barras: "",
      email: "",
      AccesSCA: "",
      Data_naixement: "",
      codiErrorDni: 0,
      ID_usuari_creacio: decryptString(localStorage.getItem('idUser')),
    };

    const body = ref(JSON.parse(JSON.stringify(blankPersonData)));

    const onSubmit = async () => {
      const data = [];
      data.push(body.value)

      const onError = [false, ""];

      if (body.value.codiErrorDni === 0) {
        await axios
          .post("/accred-doc-detailed", data)
          .then((result) => {
            onError[0] = false;
          })
          .catch((error) => {
            onError[0] = true;
            onError[1] = error;
          });
        return onError;
      } else {
        onError[0] = true;
        onError[1] = "El DNI no es correcte";
        return onError;
      }
    };


    return {
      body,
      onSubmit,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
